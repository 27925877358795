// javascript/packs/application.js
import Vue from 'vue'
import Vuex from 'vuex'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import NorthForm from 'north-form'
import store from '../store/index'
import App from '../App.vue'

const VueScrollTo = require('vue-scrollto')

Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueScrollTo)
Vue.use(NorthForm, { store })
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('north-form')
  if (element) {
    const quizData = JSON.parse(element.dataset.quiz)
    Vue.prototype.$quizData = quizData
    new Vue({
      el: '#north-form',
      store,
      render: h => h(App),
    })
  }
})
